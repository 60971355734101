import { ErrorPage } from '@/components/features/ErrorPage/ErrorPage';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';

const Page = () => {
  const { t } = useTranslation('error');
  const marketplaceChain = useMarketplaceChain();

  return (
    <>
      <NextSeo title={t('type.not-found.page-title')} noindex nofollow />
      <ErrorPage
        statusCode={404}
        label={t('type.not-found.label')}
        title={t('type.not-found.title')}
        description={t('type.not-found.description')}
        buttons={[
          {
            variant: 'primary',
            href: marketplaceChain.routePrefix,
            label: t('type.not-found.button.label'),
          },
        ]}
      />
    </>
  );
};

export const getStaticProps = () => ({
  props: {
    hasBottomSpacing: false,
  },
});

export default Page;
