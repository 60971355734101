import { ErrorNftCard } from '@/components/features/ErrorPage/ErrorNftCard';
import { Box, Container, Flex, Grid, GridItem, Heading, Stack, Text } from '@chakra-ui/react';
import { ButtonLink, MotionBox, type ButtonLinkProps } from '@sphere/ui';
import { type ErrorProps } from 'next/error';
import { rem } from 'polished';

type ErrorPageProps = Partial<ErrorProps> & {
  label?: string;
  title?: string;
  description?: string;
  buttons?: (Omit<ButtonLinkProps, 'as'> & {
    label: string;
  })[];
};

const cardVariants = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 25,
    rotate: 6,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export const ErrorPage = ({
  statusCode = 500,
  label,
  title,
  description,
  buttons,
}: ErrorPageProps) => (
  <Flex flexDirection="column" flex="1 1 auto">
    <Container position="relative">
      <Grid
        my={{
          base: 'space.32',
          md: 'space.48',
          lg: 'space.104',
        }}
        templateColumns="repeat(12, 1fr)"
        gap={6}
        justifyContent="space-between"
      >
        <GridItem w="100%" colSpan={{ base: 12, md: 7 }}>
          <Box
            mb={{
              base: 'space.24',
              md: 'space.48',
              lg: 'space.64',
            }}
          >
            {label && (
              <Text
                textTransform="uppercase"
                color="gray.5"
                fontSize="xs"
                mb="space.8"
                fontWeight="500"
                lineHeight={1.2}
              >
                {label}
              </Text>
            )}

            {title && (
              <Heading
                fontSize={{
                  base: '2xl',
                  md: '4xl',
                  lg: '5xl',
                }}
                as="h1"
                size="2xl"
                mb="space.24"
              >
                {title}
              </Heading>
            )}

            {description && (
              <Heading as="p" color="gray.5" fontSize="xl" lineHeight="xl">
                {description}
              </Heading>
            )}

            {buttons && (
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing="space.16"
                mt={{ base: 'space.24', md: 'space.40' }}
              >
                {buttons?.map((button, index) => (
                  <ButtonLink {...button} key={`error-button-${index}`} height={rem(48)}>
                    {button.label}
                  </ButtonLink>
                ))}
              </Stack>
            )}
          </Box>
        </GridItem>

        <GridItem
          w="100%"
          colStart={{ base: 1, lg: 9 }}
          colEnd={{ base: 12, lg: 13 }}
          transform={{ base: 'translateX(5%)', lg: 'unset' }}
          pb={{ base: 'space.96', lg: 0 }}
        >
          <MotionBox
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={cardVariants}
          >
            <ErrorNftCard statusCode={statusCode} />
          </MotionBox>
        </GridItem>
      </Grid>
    </Container>
  </Flex>
);
