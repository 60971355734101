import { FormatCryptoCurrency } from '@/components/primitives';
import { Image } from '@/components/shared/Image';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Card } from '@sphere/ui';
import useTranslation from 'next-translate/useTranslation';

type TokenCardProps = {
  statusCode: number;
};

export const ErrorNftCard = ({ statusCode }: TokenCardProps) => {
  const { t } = useTranslation('error');

  return (
    <Card
      role="group"
      bg="gray.2"
      pb={0}
      borderColor="gray.4"
      transition="all .2s ease"
      _hover={{ borderColor: 'gray.5' }}
    >
      <Box position="relative" overflow="hidden" borderTopRadius="radius.28">
        <Box
          background="blackBg"
          overflow="hidden"
          transition="all .2s"
          transform="scale(105%)"
          _groupHover={{
            transform: 'scale(120%)',
          }}
        >
          <Image src="/images/error/error-graphic.svg" boxSize="100%" />
        </Box>
      </Box>
      <Flex p="space.16" pt="space.12" justify="space-between" align="flex-start">
        <Box lineHeight="shorter">
          <Text
            fontSize="lg"
            noOfLines={1}
            _focus={{ outline: 'none' }}
            _focusVisible={{ boxShadow: 'outline-inset' }}
          >
            {t('card.title')}
          </Text>
          <Text mt="space.2" fontSize="sm" color="gray.6" noOfLines={1}>
            {t('card.label')}
          </Text>
        </Box>
        <FormatCryptoCurrency
          amount={statusCode}
          logoHeight={16}
          fontWeight="medium"
          fontSize={{ base: 'sm', md: 'md' }}
        />
      </Flex>
    </Card>
  );
};
